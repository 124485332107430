import React from 'react'
import { Box, jsx, Text, Card, Grid, Divider, NavLink, Button } from 'theme-ui'
import { FC, useEffect, useState } from 'react'
import { Bag } from '@components/icons'
// import { IoCloseOutline } from "react-icons/io5";
// import { IoCloseOutline } from "react-icons/io5";
import type { IconBaseProps } from 'react-icons';
// import { IoCloseOutline } from "@react-icons/io5";
import { X } from 'lucide-react'


import { useCart, useCheckoutUrl } from '@lib/shopify/storefront-data-hooks'
import CartItem from '../CartItem'
import { BuilderComponent, builder } from '@builder.io/react'
import env from '@config/env'

interface CartSidebarViewProps {
  onClose: () => void;
}

const CartSidebarView: FC<CartSidebarViewProps> = ({ onClose }) => {
  const checkoutUrl = useCheckoutUrl()
  const cart = useCart()
  const subTotal = (cart?.subtotalPrice as any)?.amount || cart?.subtotalPrice || '-';
  const total = ' - '

  const items = cart?.lineItems ?? []
  const isEmpty = items.length === 0
  const [cartUpsell, setCartUpsell] = useState()
  


  useEffect(() => {
    async function fetchContent() {
      const items = cart?.lineItems || []
      const cartUpsellContent = await builder
        .get('cart-upsell-sidebar', {
          cacheSeconds: 500,
          userAttributes: {
            itemInCart: items.map((item: any) => item.variant.product.handle),
          } as any,
        })
        .toPromise()
      setCartUpsell(cartUpsellContent)
    }
    fetchContent()
  }, [cart?.lineItems])

  return (
    <Box
      sx={{
        zIndex: 100000,
        height: '100vh', // Adjust to account for the 25% viewport height used by another component
        display: 'flex',
        flexDirection: 'column',
        bg: '#121212',
        color: '#fff',
        fontFamily: 'Basic',
        border: '1px solid',
        borderColor: '#252525',
        borderWidth: '.5px',
        borderRadius: '10px', // Add this line to round the borders
        borderStyle: 'solid',
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: '100vw',
        maxWidth: '500px', // Adjust as needed
      }}
    >
      <Button
        onClick={onClose} // Use the onClose prop here
        sx={{
          background: 'transparent',
          marginTop: '6rem',
          border: 'none',
          cursor: 'pointer',
          p: 1,
          '&:hover': { opacity: 0.7 },
          display: 'flex', // Add this to control alignment
          alignItems: 'center', // Vertically center the icon
          alignSelf: 'flex-start', // Align the button itself to the left
        }}
        aria-label="Close cart"
      >
              {/* <IoCloseOutlineIcon size={36} color="white" /> */}

{/* <Button
  onClick={onClose}
  sx={{
    background: 'transparent',
    marginTop: '6rem',
    border: 'none',
    cursor: 'pointer',
    p: 1,
    '&:hover': { opacity: 0.7 },
  }}
  aria-label="Close cart"
>
  <CloseIcon size={36} color="white" />
</Button> */}



<Button onClick={onClose} sx={{ /* existing styles */ }} aria-label="Close cart">
  {/* <IoCloseOutline size={36} color="white" /> */}
  <X size={36} color="white" />
</Button>
{/* <Button
  onClick={onClose}
  sx={{
    background: 'transparent',
    marginTop: '6rem',
    border: 'none',
    cursor: 'pointer',
    p: 1,
    '&:hover': { opacity: 0.7 },
  }}
  aria-label="Close cart"
>
  <IoCloseOutline size={36} color="white" />
</Button> */}
        {/* <IoCloseOutline size={36} color="white" /> Ensure icon size and color */}
      </Button>
      <Box sx={{ p: 3, borderBottom: '1px solid', borderColor: 'primary', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Text sx={{ fontFamily: 'Basic', fontSize: '24px', fontWeight: 'bold' }}>Your Cart</Text>
      </Box>
      {isEmpty ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <Bag />
          <Text sx={{ fontFamily: 'Basic', fontSize: '30px' }}>Your cart is empty</Text>
          <Text sx={{ fontFamily: 'body' }}>
            Go shop!
          </Text>
        </Box>
      ) : (
        <>
          {/* <Box sx={{ p: 3, borderBottom: '1px solid', borderColor: '#252525', borderWidth: '.25px', borderRadius: '8px' }}>
            <Text sx={{ fontFamily: 'Basic', fontSize: '24px', fontWeight: 'bold' }}>Your Cart</Text>
          </Box> */}
          <Box sx={{ flexGrow: 1, overflowY: 'auto', px: 3, py: 4 }}>
            {items.map((item: any) => (
              <CartItem
                key={item.id}
                item={item}
                currencyCode={item.variant?.priceV2?.currencyCode || 'USD'}
              />
            ))}
            <BuilderComponent content={cartUpsell} model="cart-upsell-sidebar" />
          </Box>
          <Box sx={{ px: 3, py: 4, borderTop: '1px solid', borderColor: '#252525', bg: '#121212' }}>
            <Card sx={{ fontSize: '19px', fontFamily: 'Basic', mb: 4, bg: 'transparent', boxShadow: 'none' }}>
              <Grid gap={1} columns={2} sx={{ my: 3 }}>
                <Text>Subtotal:</Text>
                <Text sx={{ marginLeft: 'auto' }}>${subTotal}</Text>
                <Text>Shipping:</Text>
                <Text sx={{ marginLeft: 'auto' }}> Calculated at checkout </Text>
                <Text>Tax: </Text>
                <Text sx={{ marginLeft: 'auto' }}> Calculated at checkout </Text>
              </Grid>
              <Divider />
              <Grid gap={1} columns={2}>
                <Text variant="bold">Estimated Total:</Text>
                <Text variant="bold" sx={{ marginLeft: 'auto' }}>
                  {total}
                </Text>
              </Grid>
            </Card>
            {checkoutUrl && (
              <NavLink
                variant="nav"
                sx={{
                  width: '100%',
                  p: 3,
                  fontFamily: 'Basic',
                  fontSize: '20px',
                  textAlign: 'center',
                  backgroundColor: 'primary',
                  color: '#f0f0f0',
                  transition: 'all 0.2s ease-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    backgroundColor: '#2A2A2A',
                    color: '#ffffff',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  },
                }}
                href={checkoutUrl!}
              >
                Proceed to Checkout
              </NavLink>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

export default CartSidebarView


// import React from 'react'
// import { Box, jsx, Text, Card, Grid, Divider, NavLink, Button } from 'theme-ui'
// import { FC, useEffect, useState } from 'react'
// import { Bag } from '@components/icons'
// import { X } from 'lucide-react'
// import { IoCloseOutline } from "react-icons/io5";
// import { useCart, useCheckoutUrl } from '@lib/shopify/storefront-data-hooks'
// import CartItem from '../CartItem'
// import { BuilderComponent, builder } from '@builder.io/react'
// import env from '@config/env'

// interface CartSidebarViewProps {
//   onClose: () => void;
// }

// const CartSidebarView: FC<CartSidebarViewProps> = ({ onClose }) => {
//   const checkoutUrl = useCheckoutUrl()
//   const cart = useCart()
//   const subTotal = (cart?.subtotalPrice as any)?.amount || cart?.subtotalPrice || '-';
//   const total = ' - '

//   const items = cart?.lineItems ?? []
//   const isEmpty = items.length === 0
//   const [cartUpsell, setCartUpsell] = useState()

//   useEffect(() => {
//     async function fetchContent() {
//       const items = cart?.lineItems || []
//       const cartUpsellContent = await builder
//         .get('cart-upsell-sidebar', {
//           cacheSeconds: 120,
//           userAttributes: {
//             itemInCart: items.map((item: any) => item.variant.product.handle),
//           } as any,
//         })
//         .toPromise()
//       setCartUpsell(cartUpsellContent)
//     }
//     fetchContent()
//   }, [cart?.lineItems])

//   return (
//     <Box
//       sx={{
//         height: '100vh',
//         display: 'flex',
//         flexDirection: 'column',
//         bg: '#121212',
//         color: '#fff',
//         fontFamily: 'Basic',
//         border: '1px solid',
//         borderColor: 'primary',
//         borderWidth: '2px',
//         borderStyle: 'solid',
//         zIndex: 2147483647,
//         position: 'fixed',
//         top: 0,
//         right: 0,
//         width: '100%',
//         maxWidth: '500px',
//         '@media screen and (max-width: 500px)': {
//           maxWidth: '100%',
//         },
//       }}
//     >
//       <Box sx={{ 
//         p: 3, 
//         borderBottom: '1px solid', 
//         borderColor: 'primary', 
//         display: 'flex', 
//         justifyContent: 'space-between', 
//         alignItems: 'center'
//       }}>
//             <Button
//           onClick={onClose}
//           sx={{
//             background: 'transparent',
//             border: 'none',
//             cursor: 'pointer',
//             p: 1,
//             '&:hover': { opacity: 0.7 },
//             color: 'white',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//           }}
//           aria-label="Close cart"
//         >
//           <IoCloseOutline size={36} color="white" />
//         </Button>

//         {/* <Text sx={{ fontFamily: 'Basic', fontSize: '24px', fontWeight: 'bold' }}>Your Cart</Text> */}
    
//       </Box>
      
//       {isEmpty ? (
//         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
//           <Bag />
//           <Text sx={{ fontFamily: 'Basic', fontSize: '30px', mt: 3 }}>Your cart is empty</Text>
//           <Text sx={{ fontFamily: 'body', mt: 2 }}>
//             Go shop!
//           </Text>
//         </Box>
//       ) : (
//         <>
//           <Box sx={{ flexGrow: 1, overflowY: 'auto', px: 3, py: 4 }}>
//             {items.map((item: any) => (
//               <CartItem
//                 key={item.id}
//                 item={item}
//                 currencyCode={item.variant?.priceV2?.currencyCode || 'USD'}
//               />
//             ))}
//             <BuilderComponent content={cartUpsell} model="cart-upsell-sidebar" />
//           </Box>
          
//           <Box sx={{ px: 3, py: 4, borderTop: '1px solid', borderColor: 'primary', bg: '#121212' }}>
//             <Card sx={{ fontSize: '19px', fontFamily: 'Lato', mb: 4, bg: 'transparent', boxShadow: 'none' }}>
//               <Grid gap={1} columns={2} sx={{ my: 3 }}>
//                 <Text>Subtotal:</Text>
//                 <Text sx={{ marginLeft: 'auto' }}>{subTotal}</Text>
//                 <Text>Shipping:</Text>
//                 <Text sx={{ marginLeft: 'auto' }}> - </Text>
//                 <Text>Tax: </Text>
//                 <Text sx={{ marginLeft: 'auto' }}> - </Text>
//               </Grid>
//               <Divider />
//               <Grid gap={1} columns={2}>
//                 <Text variant="bold">Estimated Total:</Text>
//                 <Text variant="bold" sx={{ marginLeft: 'auto' }}>
//                   {total}
//                 </Text>
//               </Grid>
//             </Card>
//             {checkoutUrl && (
//               <NavLink
//                 variant="nav"
//                 sx={{
//                   width: '100%',
//                   p: 3,
//                   fontFamily: 'Lato',
//                   fontSize: '20px',
//                   textAlign: 'center',
//                   backgroundColor: 'primary',
//                   color: '#f0f0f0',
//                   transition: 'all 0.2s ease-out',
//                   '&:hover': {
//                     transform: 'translateY(-2px)',
//                     backgroundColor: '#2A2A2A',
//                     color: '#ffffff',
//                     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//                   },
//                 }}
//                 href={checkoutUrl!}
//               >
//                 Proceed to Checkout
//               </NavLink>
//             )}
//           </Box>
//         </>
//       )}
//     </Box>
//   )
// }

// export default CartSidebarView

// import React from 'react'
// import { Box, jsx, Text, Card, Grid, Divider, NavLink, Button } from 'theme-ui'
// import { FC, useEffect, useState } from 'react'
// import { Bag } from '@components/icons'
// import { X } from 'lucide-react'
// import { IoCloseOutline } from "react-icons/io5";
// import { useCart, useCheckoutUrl } from '@lib/shopify/storefront-data-hooks'
// import CartItem from '../CartItem'
// import { BuilderComponent, builder } from '@builder.io/react'
// import env from '@config/env'

// interface CartSidebarViewProps {
//   onClose: () => void;
// }

// const CartSidebarView: FC<CartSidebarViewProps> = ({ onClose }) => {
//   const checkoutUrl = useCheckoutUrl()
//   const cart = useCart()
//   const subTotal = (cart?.subtotalPrice as any)?.amount || cart?.subtotalPrice || '-';
//   const total = ' - '
//   const [isCartOpen, setIsCartOpen] = useState(true);
//   const items = cart?.lineItems ?? []
//   const isEmpty = items.length === 0
//   const [cartUpsell, setCartUpsell] = useState()

//   useEffect(() => {
//     async function fetchContent() {
//       const items = cart?.lineItems || []
//       const cartUpsellContent = await builder
//         .get('cart-upsell-sidebar', {
//           cacheSeconds: 120,
//           userAttributes: {
//             itemInCart: items.map((item: any) => item.variant.product.handle),
//           } as any,
//         })
//         .toPromise()
//       setCartUpsell(cartUpsellContent)
//     }
//     fetchContent()
//   }, [cart?.lineItems])

//   const handleCloseCart = () => {
//     setIsCartOpen(false);
//   };

//   return (
//     <Box
//       sx={{
//         height: '100vh',
//         display: 'flex',
//         flexDirection: 'column',
//         bg: '#121212',
//         color: '#fff',
//         fontFamily: 'Basic',
//         border: '1px solid',
//         borderColor: 'primary',
//         borderWidth: '2px',
//         borderStyle: 'solid',
//         zIndex: 2147483647,
//         position: 'fixed',
//         top: 0,
//         right: 0,
//         width: '100%',
//         maxWidth: '500px',
//         '@media screen and (max-width: 500px)': {
//           maxWidth: '100%',
//         },
//       }}
//     >
//       <Box sx={{ 
//         p: 3, 
//         borderBottom: '1px solid', 
//         borderColor: 'primary', 
//         display: 'flex', 
//         justifyContent: 'space-between', 
//         alignItems: 'center'
//       }}>
//             <Button
//           onClick={onClose}
//           sx={{
//             background: 'transparent',
//             border: 'none',
//             cursor: 'pointer',
//             p: 1,
//             '&:hover': { opacity: 0.7 },
//             color: 'white',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//           }}
//           aria-label="Close cart"
//         >
//           <IoCloseOutline size={36} color="white" />
//         </Button>

//         {/* <Text sx={{ fontFamily: 'Basic', fontSize: '24px', fontWeight: 'bold' }}>Your Cart</Text> */}
    
//       </Box>
      
//       {isEmpty ? (
//         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
//           <Bag />
//           <Text sx={{ fontFamily: 'Basic', fontSize: '30px', mt: 3 }}>Your cart is empty</Text>
//           <Text sx={{ fontFamily: 'body', mt: 2 }}>
//             Go shop!
//           </Text>
//         </Box>
//       ) : (
//         <>
//           <Box sx={{ flexGrow: 1, overflowY: 'auto', px: 3, py: 4 }}>
//             {items.map((item: any) => (
//               <CartItem
//                 key={item.id}
//                 item={item}
//                 currencyCode={item.variant?.priceV2?.currencyCode || 'USD'}
//               />
//             ))}
//             <BuilderComponent content={cartUpsell} model="cart-upsell-sidebar" />
//           </Box>
          
//           <Box sx={{ px: 3, py: 4, borderTop: '1px solid', borderColor: 'primary', bg: '#121212' }}>
//             <Card sx={{ fontSize: '19px', fontFamily: 'Lato', mb: 4, bg: 'transparent', boxShadow: 'none' }}>
//               <Grid gap={1} columns={2} sx={{ my: 3 }}>
//                 <Text>Subtotal:</Text>
//                 <Text sx={{ marginLeft: 'auto' }}>{subTotal}</Text>
//                 <Text>Shipping:</Text>
//                 <Text sx={{ marginLeft: 'auto' }}> - </Text>
//                 <Text>Tax: </Text>
//                 <Text sx={{ marginLeft: 'auto' }}> - </Text>
//               </Grid>
//               <Divider />
//               <Grid gap={1} columns={2}>
//                 <Text variant="bold">Estimated Total:</Text>
//                 <Text variant="bold" sx={{ marginLeft: 'auto' }}>
//                   {total}
//                 </Text>
//               </Grid>
//             </Card>
//             {checkoutUrl && (
//               <NavLink
//                 variant="nav"
//                 sx={{
//                   width: '100%',
//                   p: 3,
//                   fontFamily: 'Lato',
//                   fontSize: '20px',
//                   textAlign: 'center',
//                   backgroundColor: 'primary',
//                   color: '#f0f0f0',
//                   transition: 'all 0.2s ease-out',
//                   '&:hover': {
//                     transform: 'translateY(-2px)',
//                     backgroundColor: '#2A2A2A',
//                     color: '#ffffff',
//                     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//                   },
//                 }}
//                 href={checkoutUrl!}
//               >
//                 Proceed to Checkout
//               </NavLink>
//             )}
//           </Box>
//         </>
//       )}
//     </Box>
//   )
// }

// export default CartSidebarView

// import React from 'react'
// import { Box, jsx, Text, Card, Grid, Divider, NavLink, Button } from 'theme-ui'
// import { FC, useEffect, useState } from 'react'
// import { Bag } from '@components/icons'
// import { X } from 'lucide-react'
// import { IoCloseOutline } from "react-icons/io5";
// import { useCart, useCheckoutUrl } from '@lib/shopify/storefront-data-hooks'
// import CartItem from '../CartItem'
// import { BuilderComponent, builder } from '@builder.io/react'
// import env from '@config/env'

// const CartSidebarView: FC = () => {
//   const checkoutUrl = useCheckoutUrl()
//   const cart = useCart()
//   const subTotal = (cart?.subtotalPrice as any)?.amount || cart?.subtotalPrice || '-';
//   const total = ' - '

//   const items = cart?.lineItems ?? []
//   const isEmpty = items.length === 0
//   const [cartUpsell, setCartUpsell] = useState()

//   useEffect(() => {
//     async function fetchContent() {
//       const items = cart?.lineItems || []
//       const cartUpsellContent = await builder
//         .get('cart-upsell-sidebar', {
//           cacheSeconds: 120,
//           userAttributes: {
//             itemInCart: items.map((item: any) => item.variant.product.handle),
//           } as any,
//         })
//         .toPromise()
//       setCartUpsell(cartUpsellContent)
//     }
//     fetchContent()
//   }, [cart?.lineItems])

//   const handleClose = () => {
//     // Implement close functionality here
//     console.log('Close cart sidebar')
//   }

//   return (
//     <Box
//       sx={{
//         height: '100vh',
//         display: 'flex',
//         flexDirection: 'column',
//         bg: '#121212',
//         color: '#fff',
//         fontFamily: 'Basic',
//         border: '1px solid',
//         borderColor: 'primary',
//         borderWidth: '2px',
//         borderStyle: 'solid',
//         zIndex: 2147483647,
//         position: 'fixed',
//         top: 0,
//         right: 0,
//         width: '100%',
//         maxWidth: '500px',
//         '@media screen and (max-width: 500px)': {
//           maxWidth: '100%',
//         },
//       }}
//     >
//       <Box sx={{ 
//         p: 3, 
//         borderBottom: '1px solid', 
//         borderColor: 'primary', 
//         display: 'flex', 
//         justifyContent: 'space-between', 
//         alignItems: 'center'
//       }}>
//             <Button
//           onClick={handleClose}
//           sx={{
//             background: 'transparent',
//             border: 'none',
//             cursor: 'pointer',
//             p: 1,
//             '&:hover': { opacity: 0.7 },
//             color: 'white',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//           }}
//           aria-label="Close cart"
//         >
//           <IoCloseOutline size={36} color="white" />
//         </Button>


//         <Text sx={{ fontFamily: 'Basic', fontSize: '24px', fontWeight: 'bold' }}>Your Cart</Text>
    
//       </Box>
      
//       {isEmpty ? (
//         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
//           <Bag />
//           <Text sx={{ fontFamily: 'Basic', fontSize: '30px', mt: 3 }}>Your cart is empty</Text>
//           <Text sx={{ fontFamily: 'body', mt: 2 }}>
//             Go shop!
//           </Text>
//         </Box>
//       ) : (
//         <>
//           <Box sx={{ flexGrow: 1, overflowY: 'auto', px: 3, py: 4 }}>
//             {items.map((item: any) => (
//               <CartItem
//                 key={item.id}
//                 item={item}
//                 currencyCode={item.variant?.priceV2?.currencyCode || 'USD'}
//               />
//             ))}
//             <BuilderComponent content={cartUpsell} model="cart-upsell-sidebar" />
//           </Box>
          
//           <Box sx={{ px: 3, py: 4, borderTop: '1px solid', borderColor: 'primary', bg: '#121212' }}>
//             <Card sx={{ fontSize: '19px', fontFamily: 'Lato', mb: 4, bg: 'transparent', boxShadow: 'none' }}>
//               <Grid gap={1} columns={2} sx={{ my: 3 }}>
//                 <Text>Subtotal:</Text>
//                 <Text sx={{ marginLeft: 'auto' }}>{subTotal}</Text>
//                 <Text>Shipping:</Text>
//                 <Text sx={{ marginLeft: 'auto' }}> - </Text>
//                 <Text>Tax: </Text>
//                 <Text sx={{ marginLeft: 'auto' }}> - </Text>
//               </Grid>
//               <Divider />
//               <Grid gap={1} columns={2}>
//                 <Text variant="bold">Estimated Total:</Text>
//                 <Text variant="bold" sx={{ marginLeft: 'auto' }}>
//                   {total}
//                 </Text>
//               </Grid>
//             </Card>
//             {checkoutUrl && (
//               <NavLink
//                 variant="nav"
//                 sx={{
//                   width: '100%',
//                   p: 3,
//                   fontFamily: 'Lato',
//                   fontSize: '20px',
//                   textAlign: 'center',
//                   backgroundColor: 'primary',
//                   color: '#f0f0f0',
//                   transition: 'all 0.2s ease-out',
//                   '&:hover': {
//                     transform: 'translateY(-2px)',
//                     backgroundColor: '#2A2A2A',
//                     color: '#ffffff',
//                     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//                   },
//                 }}
//                 href={checkoutUrl!}
//               >
//                 Proceed to Checkout
//               </NavLink>
//             )}
//           </Box>
//         </>
//       )}
//     </Box>
//   )
// }

// export default CartSidebarView

// import React from 'react'
// import { Box, jsx, Text, Card, Grid, Divider, NavLink, Button } from 'theme-ui'
// import { FC, useEffect, useState } from 'react'
// import { Bag } from '@components/icons'
// import { X } from 'lucide-react'
// import { IoCloseOutline } from "react-icons/io5";
// import { useCart, useCheckoutUrl } from '@lib/shopify/storefront-data-hooks'
// import CartItem from '../CartItem'
// import { BuilderComponent, builder } from '@builder.io/react'
// import env from '@config/env'

// const CartSidebarView: FC = () => {
//   const checkoutUrl = useCheckoutUrl()
//   const cart = useCart()
//   const subTotal = (cart?.subtotalPrice as any)?.amount || cart?.subtotalPrice || '-';
//   const total = ' - '

//   const items = cart?.lineItems ?? []
//   const isEmpty = items.length === 0
//   const [cartUpsell, setCartUpsell] = useState()

//   useEffect(() => {
//     async function fetchContent() {
//       const items = cart?.lineItems || []
//       const cartUpsellContent = await builder
//         .get('cart-upsell-sidebar', {
//           cacheSeconds: 120,
//           userAttributes: {
//             itemInCart: items.map((item: any) => item.variant.product.handle),
//           } as any,
//         })
//         .toPromise()
//       setCartUpsell(cartUpsellContent)
//     }
//     fetchContent()
//   }, [cart?.lineItems])

//   const handleClose = () => {
//     // Implement close functionality here
//     console.log('Close cart sidebar')
//   }

//   return (
//     <Box
//       sx={{
//         height: '100vh',
//         display: 'flex',
//         flexDirection: 'column',
//         bg: '#121212',
//         color: '#fff',
//         fontFamily: 'Basic',
//         border: '1px solid',
//         borderColor: 'primary',
//         borderWidth: '2px',
//         borderStyle: 'solid',
//         zIndex: 2147483647,
//         position: 'fixed',
//         top: 0,
//         right: 0,
//         width: '100%',
//         maxWidth: '500px',
//       }}
//     >
//        <Button
//           onClick={handleClose}
//           sx={{
//             background: 'transparent',
//             border: 'none',
//             cursor: 'pointer',
//             marginTop: '.5rem',
//             marginLeft: '.1rem',
//             p: 1,
//             '&:hover': { opacity: 0.7 },
//             color: 'white',
//           }}
//           aria-label="Close cart"
//         >
//                     <IoCloseOutline size={36} color="white"  />

//         </Button>
//       <Box sx={{ p: 3, borderBottom: '1px solid', borderColor: 'primary', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//         <Text sx={{ fontFamily: 'Basic', fontSize: '24px', fontWeight: 'bold' }}>Your Cart</Text>
       
//       </Box>
      
//       {isEmpty ? (
//         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
//           <Bag />
//           <Text sx={{ fontFamily: 'Basic', fontSize: '30px', mt: 3 }}>Your cart is empty</Text>
//           <Text sx={{ fontFamily: 'body', mt: 2 }}>
//             Go shop!
//           </Text>
//         </Box>
//       ) : (
//         <>
//           <Box sx={{ flexGrow: 1, overflowY: 'auto', px: 3, py: 4 }}>
//             {items.map((item: any) => (
//               <CartItem
//                 key={item.id}
//                 item={item}
//                 currencyCode={item.variant?.priceV2?.currencyCode || 'USD'}
//               />
//             ))}
//             <BuilderComponent content={cartUpsell} model="cart-upsell-sidebar" />
//           </Box>
          
//           <Box sx={{ px: 3, py: 4, borderTop: '1px solid', borderColor: 'primary', bg: '#121212' }}>
//             <Card sx={{ fontSize: '19px', fontFamily: 'Lato', mb: 4, bg: 'transparent', boxShadow: 'none' }}>
//               <Grid gap={1} columns={2} sx={{ my: 3 }}>
//                 <Text>Subtotal:</Text>
//                 <Text sx={{ marginLeft: 'auto' }}>{subTotal}</Text>
//                 <Text>Shipping:</Text>
//                 <Text sx={{ marginLeft: 'auto' }}> - </Text>
//                 <Text>Tax: </Text>
//                 <Text sx={{ marginLeft: 'auto' }}> - </Text>
//               </Grid>
//               <Divider />
//               <Grid gap={1} columns={2}>
//                 <Text variant="bold">Estimated Total:</Text>
//                 <Text variant="bold" sx={{ marginLeft: 'auto' }}>
//                   {total}
//                 </Text>
//               </Grid>
//             </Card>
//             {checkoutUrl && (
//               <NavLink
//                 variant="nav"
//                 sx={{
//                   width: '100%',
//                   p: 3,
//                   fontFamily: 'Lato',
//                   fontSize: '20px',
//                   textAlign: 'center',
//                   backgroundColor: 'primary',
//                   color: '#f0f0f0',
//                   transition: 'all 0.2s ease-out',
//                   '&:hover': {
//                     transform: 'translateY(-2px)',
//                     backgroundColor: '#2A2A2A',
//                     color: '#ffffff',
//                     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//                   },
//                 }}
//                 href={checkoutUrl!}
//               >
//                 Proceed to Checkout
//               </NavLink>
//             )}
//           </Box>
//         </>
//       )}
//     </Box>
//   )
// }

// export default CartSidebarView



/// last version!!!!! goooooooood

// import React from 'react'
// import { Box, jsx, Text, Card, Grid, Divider, NavLink, Button } from 'theme-ui'
// import { FC, useEffect, useState } from 'react'
// import { Bag } from '@components/icons'
// import { IoCloseOutline } from "react-icons/io5"; // Ensure correct import
// import { useCart, useCheckoutUrl } from '@lib/shopify/storefront-data-hooks'
// import CartItem from '../CartItem'
// import { BuilderComponent, builder } from '@builder.io/react'
// import env from '@config/env'

// const CartSidebarView: FC = () => {
//   const checkoutUrl = useCheckoutUrl()
//   const cart = useCart()
//   const subTotal = (cart?.subtotalPrice as any)?.amount || cart?.subtotalPrice || '-';
//   const total = ' - '

//   const items = cart?.lineItems ?? []
//   const isEmpty = items.length === 0
//   const [cartUpsell, setCartUpsell] = useState()

//   useEffect(() => {
//     async function fetchContent() {
//       const items = cart?.lineItems || []
//       const cartUpsellContent = await builder
//         .get('cart-upsell-sidebar', {
//           cacheSeconds: 120,
//           userAttributes: {
//             itemInCart: items.map((item: any) => item.variant.product.handle),
//           } as any,
//         })
//         .toPromise()
//       setCartUpsell(cartUpsellContent)
//     }
//     fetchContent()
//   }, [cart?.lineItems])

//   const handleClose = () => {
//     // Implement close functionality here
//     console.log('Close cart sidebar')
//   }

//   return (
//     <Box
//       sx={{
//         height: '100vh', // Adjust to account for the 25% viewport height used by another component
//         display: 'flex',
//         flexDirection: 'column',
//         bg: '#121212',
//         color: '#fff',
//         fontFamily: 'Basic',
//         border: '1px solid',
//         borderColor: '#252525',
//         borderWidth: '.5px',
//         borderRadius: '10px', // Add this line to round the borders
//         borderStyle: 'solid',
//         zIndex: 999999999999999999999,
//         position: 'fixed',
//         bottom: 0,
//         right: 0,
//         width: '100vw',
//         maxWidth: '500px', // Adjust as needed
//       }}
//     >
//       <Button
//         onClick={handleClose}
//         sx={{
//           background: 'transparent',
//           border: 'none',
//           cursor: 'pointer',
//           p: 1,
//           '&:hover': { opacity: 0.7 },
//         }}
//         aria-label="Close cart"
//       >
//         <IoCloseOutline size={36} color="white" /> {/* Ensure icon size and color */}
//       </Button>
//       <Box sx={{ p: 3, borderBottom: '1px solid', borderColor: 'primary', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//         <Text sx={{ fontFamily: 'Basic', fontSize: '24px', fontWeight: 'bold' }}>Your Cart</Text>
//       </Box>
//       {isEmpty ? (
//         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
//           <Bag />
//           <Text sx={{ fontFamily: 'Basic', fontSize: '30px' }}>Your cart is empty</Text>
//           <Text sx={{ fontFamily: 'body' }}>
//             Go shop!
//           </Text>
//         </Box>
//       ) : (
//         <>
//           <Box sx={{ p: 3, borderBottom: '1px solid', borderColor: '#252525', borderWidth: '.25px', borderRadius: '8px' }}>
//             <Text sx={{ fontFamily: 'Basic', fontSize: '24px', fontWeight: 'bold' }}>Your Cart</Text>
//           </Box>
//           <Box sx={{ flexGrow: 1, overflowY: 'auto', px: 3, py: 4 }}>
//             {items.map((item: any) => (
//               <CartItem
//                 key={item.id}
//                 item={item}
//                 currencyCode={item.variant?.priceV2?.currencyCode || 'USD'}
//               />
//             ))}
//             <BuilderComponent content={cartUpsell} model="cart-upsell-sidebar" />
//           </Box>
//           <Box sx={{ px: 3, py: 4, borderTop: '1px solid', borderColor: '#252525', bg: '#121212' }}>
//             <Card sx={{ fontSize: '19px', fontFamily: 'Basic', mb: 4, bg: 'transparent', boxShadow: 'none' }}>
//               <Grid gap={1} columns={2} sx={{ my: 3 }}>
//                 <Text>Subtotal:</Text>
//                 <Text sx={{ marginLeft: 'auto' }}>{subTotal}</Text>
//                 <Text>Shipping:</Text>
//                 <Text sx={{ marginLeft: 'auto' }}> - </Text>
//                 <Text>Tax: </Text>
//                 <Text sx={{ marginLeft: 'auto' }}> - </Text>
//               </Grid>
//               <Divider />
//               <Grid gap={1} columns={2}>
//                 <Text variant="bold">Estimated Total:</Text>
//                 <Text variant="bold" sx={{ marginLeft: 'auto' }}>
//                   {total}
//                 </Text>
//               </Grid>
//             </Card>
//             {checkoutUrl && (
//               <NavLink
//                 variant="nav"
//                 sx={{
//                   width: '100%',
//                   p: 3,
//                   fontFamily: 'Basic',
//                   fontSize: '20px',
//                   textAlign: 'center',
//                   backgroundColor: 'primary',
//                   color: '#f0f0f0',
//                   transition: 'all 0.2s ease-out',
//                   '&:hover': {
//                     transform: 'translateY(-2px)',
//                     backgroundColor: '#2A2A2A',
//                     color: '#ffffff',
//                     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//                   },
//                 }}
//                 href={checkoutUrl!}
//               >
//                 Proceed to Checkout
//               </NavLink>
//             )}
//           </Box>
//         </>
//       )}
//     </Box>
//   )
// }

// export default CartSidebarView

// import React from 'react'
// import { Box, jsx, Text, Card, Grid, Divider, NavLink, Button } from 'theme-ui'
// import { FC, useEffect, useState } from 'react'
// import { Bag } from '@components/icons'
// import { IoCloseOutline } from "react-icons/io5";
// import { useCart, useCheckoutUrl } from '@lib/shopify/storefront-data-hooks'
// import CartItem from '../CartItem'
// import { X } from 'lucide-react' // Import the X icon
// import { BuilderComponent, builder } from '@builder.io/react'
// import env from '@config/env'

// const CartSidebarView: FC = () => {
//   const checkoutUrl = useCheckoutUrl()
//   const cart = useCart()
//   const subTotal = (cart?.subtotalPrice as any)?.amount || cart?.subtotalPrice || '-';
//   const total = ' - '

//   const items = cart?.lineItems ?? []
//   const isEmpty = items.length === 0
//   const [cartUpsell, setCartUpsell] = useState()

//   useEffect(() => {
//     async function fetchContent() {
//       const items = cart?.lineItems || []
//       const cartUpsellContent = await builder
//         .get('cart-upsell-sidebar', {
//           cacheSeconds: 120,
//           userAttributes: {
//             itemInCart: items.map((item: any) => item.variant.product.handle),
//           } as any,
//         })
//         .toPromise()
//       setCartUpsell(cartUpsellContent)
//     }
//     fetchContent()
//   }, [cart?.lineItems])


//   const handleClose = () => {
//     // Implement close functionality here
//     console.log('Close cart sidebar')
//   }
//   return (
//     <Box
//       sx={{
//         height: '100vh', // Adjust to account for the 25% viewport height used by another component
//         display: 'flex',
//         flexDirection: 'column',

//         bg: '#121212',
//         color: '#fff',
//         fontFamily: 'Basic',
//         border: '1px solid',
//         borderColor: '#252525',
//         borderWidth: '.5px',
//                 borderRadius: '10px', // Add this line to round the borders

//         borderStyle: 'solid',
//         zIndex: 999999999999999999999,
//         position: 'fixed',
//         bottom: 0,
//         right: 0,
//         width: '100vw',
//         maxWidth: '500px', // Adjust as needed
//       }}
//     >
//        <Button
//           onClick={handleClose}
//           sx={{
//             background: 'transparent',
//             border: 'none',
//             cursor: 'pointer',
//             p: 1,
//             '&:hover': { opacity: 0.7 },
//           }}
//           aria-label="Close cart"
//         >
//           <IoCloseOutline />
//         </Button>
//       <Box sx={{ p: 3, borderBottom: '1px solid', borderColor: 'primary', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        
//         <Text sx={{ fontFamily: 'Basic', fontSize: '24px', fontWeight: 'bold' }}>Your Cart</Text>
       
//       </Box>
//       {isEmpty ? (
//         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
//           <Bag />
//           <Text sx={{ fontFamily: 'Basic', fontSize: '30px' }}>Your cart is empty</Text>
//           <Text sx={{ fontFamily: 'body' }}>
//             Go shop!
//           </Text>
//         </Box>
//       ) : (
//         <>
//           <Box sx={{ p: 3, borderBottom: '1px solid',   borderColor: '#252525',
//         borderWidth: '.25px', borderRadius: '8px', // Add this line to round the borders
// }}>
//             <Text sx={{ fontFamily: 'Basic', fontSize: '24px', fontWeight: 'bold' }}>Your Cart</Text>
//           </Box>
          
//           <Box sx={{ flexGrow: 1, overflowY: 'auto', px: 3, py: 4 }}>
//             {items.map((item: any) => (
//               <CartItem
//                 key={item.id}
//                 item={item}
//                 currencyCode={item.variant?.priceV2?.currencyCode || 'USD'}
//               />
//             ))}
//             <BuilderComponent content={cartUpsell} model="cart-upsell-sidebar" />
//           </Box>
          
//           <Box sx={{ px: 3, py: 4, borderTop: '1px solid', borderColor: '#252525', bg: '#121212' }}>
//             <Card sx={{ fontSize: '19px', fontFamily: 'Basic', mb: 4, bg: 'transparent', boxShadow: 'none' }}>
//               <Grid gap={1} columns={2} sx={{ my: 3 }}>
//                 <Text>Subtotal:</Text>
//                 <Text sx={{ marginLeft: 'auto' }}>{subTotal}</Text>
//                 <Text>Shipping:</Text>
//                 <Text sx={{ marginLeft: 'auto' }}> - </Text>
//                 <Text>Tax: </Text>
//                 <Text sx={{ marginLeft: 'auto' }}> - </Text>
//               </Grid>
//               <Divider />
//               <Grid gap={1} columns={2}>
//                 <Text variant="bold">Estimated Total:</Text>
//                 <Text variant="bold" sx={{ marginLeft: 'auto' }}>
//                   {total}
//                 </Text>
//               </Grid>
//             </Card>
//             {checkoutUrl && (
//               <NavLink
//                 variant="nav"
//                 sx={{
//                   width: '100%',
//                   p: 3,
//                   fontFamily: 'Basic',
//                   fontSize: '20px',
//                   textAlign: 'center',
//                   backgroundColor: 'primary',
//                   color: '#f0f0f0',
//                   transition: 'all 0.2s ease-out',
//                   '&:hover': {
//                     transform: 'translateY(-2px)',
//                     backgroundColor: '#2A2A2A',
//                     color: '#ffffff',
//                     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//                   },
//                 }}
//                 href={checkoutUrl!}
//               >
//                 Proceed to Checkout
//               </NavLink>
//             )}
//           </Box>
//         </>
//       )}
//     </Box>
//   )
// }

// export default CartSidebarView

// import React from 'react'
// import { Box, jsx, Text, Card, Grid, Divider, NavLink } from 'theme-ui'
// import { FC, useEffect, useState } from 'react'
// import { Bag } from '@components/icons'
// import { useCart, useCheckoutUrl } from '@lib/shopify/storefront-data-hooks'
// import CartItem from '../CartItem'
// import { BuilderComponent, builder } from '@builder.io/react'
// import env from '@config/env'

// const CartSidebarView: FC = () => {
//   const checkoutUrl = useCheckoutUrl()
//   const cart = useCart()
//   const subTotal = (cart?.subtotalPrice as any)?.amount || cart?.subtotalPrice || '-';
//   const total = ' - '

//   const items = cart?.lineItems ?? []
//   const isEmpty = items.length === 0
//   const [cartUpsell, setCartUpsell] = useState()

//   useEffect(() => {
//     async function fetchContent() {
//       const items = cart?.lineItems || []
//       const cartUpsellContent = await builder
//         .get('cart-upsell-sidebar', {
//           cacheSeconds: 120,
//           userAttributes: {
//             itemInCart: items.map((item: any) => item.variant.product.handle),
//           } as any,
//         })
//         .toPromise()
//       setCartUpsell(cartUpsellContent)
//     }
//     fetchContent()
//   }, [cart?.lineItems])

//   return (
//     <Box
//       sx={{
//         height: '100%',
//         display: 'flex',
//         flexDirection: 'column',
//         bg: '#121212',
//         color: '#fff',
//         fontFamily: 'Basic',
//         border: '1px solid',
//         borderColor: 'primary',
//         borderWidth: '2px',
//         borderStyle: 'solid',
//         zIndex: 2147483647,
//       }}
//     >
//       {isEmpty ? (
//         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
//           <Bag />
//           <Text sx={{ fontFamily: 'Basic', fontSize: '30px' }}>Your cart is empty</Text>
//           <Text sx={{ fontFamily: 'body' }}>
//             Go shop!
//           </Text>
//         </Box>
//       ) : (
//         <>
//           <Box sx={{ flexGrow: 1, overflowY: 'auto', px: 3, py: 4 }}>
//             {items.map((item: any) => (
//               <CartItem
//                 key={item.id}
//                 item={item}
//                 currencyCode={item.variant?.priceV2?.currencyCode || 'USD'}
//               />
//             ))}
//             <BuilderComponent content={cartUpsell} model="cart-upsell-sidebar" />
//           </Box>
//           <Box sx={{ px: 3, py: 4, borderTop: '1px solid', borderColor: 'primary' }}>
//             <Card sx={{ fontSize: '19px', fontFamily: 'Lato', mb: 4 }}>
//               <Grid gap={1} columns={2} sx={{ my: 3 }}>
//                 <Text>Subtotal:</Text>
//                 <Text sx={{ marginLeft: 'auto' }}>{subTotal}</Text>
//                 <Text>Shipping:</Text>
//                 <Text sx={{ marginLeft: 'auto' }}> - </Text>
//                 <Text>Tax: </Text>
//                 <Text sx={{ marginLeft: 'auto' }}> - </Text>
//               </Grid>
//               <Divider />
//               <Grid gap={1} columns={2}>
//                 <Text variant="bold">Estimated Total:</Text>
//                 <Text variant="bold" sx={{ marginLeft: 'auto' }}>
//                   {total}
//                 </Text>
//               </Grid>
//             </Card>
//             {checkoutUrl && (
//               <NavLink
//                 variant="nav"
//                 sx={{
//                   width: '100%',
//                   p: 12,
//                   fontFamily: 'Lato',
//                   fontSize: '30px',
//                   textAlign: 'center',
//                   backgroundColor: 'primary',
//                   color: '#f0f0f0',
//                   transition: 'all 0.2s ease-out',
//                   '&:hover': {
//                     transform: 'translateY(-2px)',
//                     backgroundColor: '#2A2A2A',
//                     color: '#ffffff',
//                     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//                   },
//                 }}
//                 href={checkoutUrl!}
//               >
//                 Proceed to Checkout
//               </NavLink>
//             )}
//           </Box>
//         </>
//       )}
//     </Box>
//   )
// }

// export default CartSidebarView

// /** @jsxRuntime classic */
// /** @jsx jsx */
// import React from 'react'
// import { Box, jsx, Text, Card, Grid, Divider, NavLink } from 'theme-ui'
// import { FC, useEffect, useState } from 'react'
// import { Bag } from '@components/icons'
// import { useCart, useCheckoutUrl } from '@lib/shopify/storefront-data-hooks'
// import CartItem from '../CartItem'
// import { BuilderComponent, builder } from '@builder.io/react'
// import env from '@config/env'

// const CartSidebarView: FC = () => {
//   const checkoutUrl = useCheckoutUrl()
//   const cart = useCart()
//   const subTotal = (cart?.subtotalPrice as any)?.amount || cart?.subtotalPrice || '-';
//   const total = ' - '

//   const items = cart?.lineItems ?? []
//   const isEmpty = items.length === 0
//   const [cartUpsell, setCartUpsell] = useState()

//   useEffect(() => {
//     async function fetchContent() {
//       const items = cart?.lineItems || []
//       const cartUpsellContent = await builder
//         .get('cart-upsell-sidebar', {
//           cacheSeconds: 120,
//           userAttributes: {
//             itemInCart: items.map((item: any) => item.variant.product.handle),
//           } as any,
//         })
//         .toPromise()
//       setCartUpsell(cartUpsellContent)
//     }
//     fetchContent()
//   }, [cart?.lineItems])

//   return (
//     <Box
//       sx={{
//         height: '100%',
//         overflow: 'auto',
//         paddingBottom: 5,
//         bg: '#121212', // Use custom background color from theme
//         // bg: 'text',
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         px: 3,
//         color: '#fff',
//         ...(isEmpty && { justifyContent: 'center' }),
//         zIndex: 2147483647, // Maximum z-index value
//         // fontFamily: 'body',
//         fontFamily: 'Basic',
//         border: '1px solid', // Add border
//         borderColor: 'primary', // Use a color from the theme or specify a custom color
//         borderWidth: '2px', // Set the border width
//         borderStyle: 'solid', // Set the border style (solid, dashed, dotted, etc.)

//       }}
//     >
//       {isEmpty ? (
//         <>
//           <Bag />
//           <Text sx={{ fontFamily: 'Basic', fontSize: '30px' }}>Your cart is empty</Text>
//           {/* Your cart is empty */}
//           {/* <Text> */}
//           <Text sx={{ fontFamily: 'body' }}>
//             Go shop!
//           </Text>
//         </>
//       ) : (
//         <>
        
//           {items.map((item: any) => (
//             <CartItem
//               key={item.id}
//               item={item}
//               // todo update types
//               currencyCode={item.variant?.priceV2?.currencyCode || 'USD'}
//             />
//           ))}
//           <Card sx={{ marginLeft: 'auto', minWidth: '10rem', fontSize: '19px',paddingTop: 40, paddingLeft: 5, fontFamily: 'Lato' }}>
//             <Grid gap={1} columns={2} sx={{ my: 3 }}>
//               <Text>Subtotal:</Text>
//               <Text sx={{ marginLeft: 'auto' }}>{subTotal}</Text>
//               <Text>Shipping:</Text>
//               <Text sx={{ marginLeft: 'auto' }}> - </Text>
//               <Text>Tax: </Text>
//               <Text sx={{ marginLeft: 'auto' }}> - </Text>
//             </Grid>

//             <Divider />
//             <Grid gap={1} columns={2}>
//               <Text variant="bold">Estimated Total:</Text>
//               <Text variant="bold" sx={{ marginLeft: 'auto' }}>
//                 {total}
//               </Text>
//             </Grid>
//           </Card>
//           <BuilderComponent content={cartUpsell} model="cart-upsell-sidebar" />
//           {checkoutUrl && (
//             <NavLink
//               variant="nav"
//               sx={{ width: '100%', m: 2, marginTop: '35px', p: 12, fontFamily: 'Lato', fontSize: '30px', textAlign: 'center', // Light gray color close to white
//                 backgroundColor: 'primary', // Greyish-black background color
//                 color: '#f0f0f0', // Light gray color close to white
//                 transition: 'all 0.2s ease-out',
//                 '&:hover': {
//                   transform: 'translateY(-2px)',
//                   backgroundColor: '#2A2A2A',
//                   color: '#ffffff', // Pure white on hover
//                   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//                 },
                
//                 // transition: 'all 0.2s ease-out',
//                 // '&:hover': {
//                 //   transform: 'translateY(-2px)',
//                 //   backgroundColor: 'primaryHover',
//                 //   color: '#ffffff', // Pure white on hover
//                 //   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//                 // },
//               }}
//               href={checkoutUrl!}
//             >
//               Proceed to Checkout
//             </NavLink>
//           )}
//         </>
//       )}
//     </Box>
//   )
// }

// export default CartSidebarView

/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, jsx, Grid, Input, Text, IconButton } from 'theme-ui'
import React, { ChangeEvent, useEffect, useState } from 'react'
import Image from 'next/legacy/image'
import { Plus, Minus } from '@components/icons'
import { getPrice } from '@lib/shopify/storefront-data-hooks/src/utils/product'
import {
  useUpdateItemQuantity,
  useRemoveItemFromCart,
} from '@lib/shopify/storefront-data-hooks'
import Link from '@components/common/Link'

const CartItem = ({
  item,
  currencyCode,
}: {
  item: any
  currencyCode: string
}) => {
  const updateItem = useUpdateItemQuantity()
  const removeItem = useRemoveItemFromCart()
  const [quantity, setQuantity] = useState(item.quantity)
  const [removing, setRemoving] = useState(false)

  const updateQuantity = async (quantity: number) => {
    await updateItem(item.variant.id, quantity)
  }

  const handleQuantity = (e: ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.target.value)
    if (Number.isInteger(val) && val >= 0) {
      setQuantity(val)
    }
  }

  const handleBlur = () => {
    const val = Number(quantity)
    if (val !== item.quantity) {
      updateQuantity(val)
    }
  }

  const increaseQuantity = (n = 1) => {
    const val = Number(quantity) + n
    if (Number.isInteger(val) && val >= 0) {
      setQuantity(val)
      updateQuantity(val)
    }
  }

  const handleRemove = async () => {
    setRemoving(true)
    try {
      await removeItem(item.variant.id)
    } catch (error) {
      console.error(error)
      setRemoving(false)
    }
  }

  useEffect(() => {
    if (item.quantity !== Number(quantity)) {
      setQuantity(item.quantity)
    }
  }, [item.quantity])

  const selectedColor = item.variant.selectedOptions.find(
    (opt: any) => opt.name.toLowerCase() === 'color'
  )?.value

  return (
    <Grid gap={2} sx={{ width: '100%', m: 12, zIndex: 100001 }} columns={[2]}>
      <div
        sx={{
          padding: 3.5,
          border: 'solid',
          backgroundColor: '#F5F5F5',
          borderColor: '#252525',
          marginTop: '5px',
          borderWidth: '1px',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          height={130}
          width={100}
          alt={item.variant.image.altText || 'Product Image'}
          src={item.variant.image.src}
        />
      </div>
      <div>
        <Link
          href={`/product/${item.variant.product.handle}/`}
          sx={{
            fontSize: 27,
            m: 0,
            textTransform: 'uppercase',
            fontFamily: 'Basic',
            fontWeight: 700,
          }}
        >
          <>
            {item.title}
            {selectedColor && (
              <Text
                sx={{
                  fontSize: 16,
                  color: '#ccc',
                  marginTop: '0.2rem',
                  display: 'block',
                }}
              >
                Color: {selectedColor}
              </Text>
            )}
            <Text
              sx={{
                fontSize: 20,
                fontWeight: 500,
                display: 'block',
                marginTop: '.2rem',
                fontFamily: 'Lato',
                color: '#f6f6f6',
              }}
            >
              {getPrice(
                item.variant.priceV2.amount,
                item.variant.priceV2.currencyCode || 'USD'
              )}
            </Text>
          </>
        </Link>
        <ul
          sx={{
            mt: 10,
            color: '#fff',
            mb: 0,
            fontFamily: 'Basic',
            padding: 0,
            listStyle: 'none',
          }}
        >
          <li>
            <div sx={{ display: 'flex', justifyItems: 'center' }}>
              <IconButton onClick={() => increaseQuantity(-1)}>
                <Minus width={18} height={18} />
              </IconButton>
              <label>
                <Input
                  sx={{
                    border: 'solid',
                    borderColor: '#252525',
                    borderWidth: '.50px',
                    borderRadius: '10px',
                    height: '100%',
                    textAlign: 'center',
                  }}
                  type="number"
                  max={99}
                  min={0}
                  value={quantity}
                  onChange={handleQuantity}
                  onBlur={handleBlur}
                />
              </label>
              <IconButton onClick={() => increaseQuantity(1)}>
                <Plus width={18} height={18} />
              </IconButton>
            </div>
          </li>
        </ul>
      </div>
    </Grid>
  )
}

export default CartItem
//preserved
// /** @jsxRuntime classic */
// /** @jsx jsx */
// import { Box, jsx, Grid, Input, Text, IconButton } from 'theme-ui'
// import React, { ChangeEvent, useEffect, useState } from 'react'
// import Image from 'next/legacy/image'
// import { Plus, Minus } from '@components/icons'
// import { getPrice } from '@lib/shopify/storefront-data-hooks/src/utils/product'
// import {
//   useUpdateItemQuantity,
//   useRemoveItemFromCart,
// } from '@lib/shopify/storefront-data-hooks'
// import Link from '@components/common/Link'
// const CartItem = ({
//   item,
//   currencyCode,
// }: {
//   item: /*ShopifyBuy.LineItem todo: check if updated types*/ any
//   currencyCode: string
// }) => {
//   const updateItem = useUpdateItemQuantity()
//   const removeItem = useRemoveItemFromCart()
//   const [quantity, setQuantity] = useState(item.quantity)
//   const [removing, setRemoving] = useState(false)
//   const updateQuantity = async (quantity: number) => {
//     await updateItem(item.variant.id, quantity)
//   }
//   const handleQuantity = (e: ChangeEvent<HTMLInputElement>) => {
//     const val = Number(e.target.value)

//     if (Number.isInteger(val) && val >= 0) {
//       setQuantity(val)
//     }
//   }
//   const handleBlur = () => {
//     const val = Number(quantity)

//     if (val !== item.quantity) {
//       updateQuantity(val)
//     }
//   }
//   const increaseQuantity = (n = 1) => {
//     const val = Number(quantity) + n

//     if (Number.isInteger(val) && val >= 0) {
//       setQuantity(val)
//       updateQuantity(val)
//     }
//   }
//   const handleRemove = async () => {
//     setRemoving(true)

//     try {
//       // If this action succeeds then there's no need to do `setRemoving(true)`
//       // because the component will be removed from the view
//       await removeItem(item.variant.id)
//     } catch (error) {
//       console.error(error)
//       setRemoving(false)
//     }
//   }

//   useEffect(() => {
//     // Reset the quantity state if the item quantity changes
//     if (item.quantity !== Number(quantity)) {
//       setQuantity(item.quantity)
//     }
//   }, [item.quantity])

//   return (
//     <Grid gap={2} sx={{ width: '100%', m: 12, zIndex: 100001 }} columns={[2]}>
//       <div
//         sx={{
//           padding: 3.5,
//           border: 'solid',
//           backgroundColor: '#F5F5F5',
//           borderColor: '#252525',
//           marginTop:'5px',
//         borderWidth: '1px', borderRadius: '5px',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//         }}
//       >
//         <Image
//           height={130}
//           width={100}
//           alt={item.variant.image.altText || 'Product Image'}
//           src={item.variant.image.src}
//         />
//       </div>
//       <div>
//         <Link
//           href={`/product/${item.variant.product.handle}/`}
//           sx={{ fontSize: 27, m: 0,fontStyle:'uppercase', fontFamily: 'Basic', fontWeight: 700 }}
//         >
//           <>
//             {item.title}
//             <Text
//               sx={{
//                 fontSize: 20,
//                 fontWeight:500,
//                 display: 'block',
//                 marginLeft: 'auto',
//                 marginTop:'.2rem',
//                 fontFamily: 'Lato',
//                 color: '#f6f6f6',
//                 //this is price
//               }}
//             >
//               {getPrice(
//                 item.variant.priceV2.amount,
//                 item.variant.priceV2.currencyCode || 'USD'
//               )}
//             </Text>
//           </>
//         </Link>
//         <ul sx={{ mt: 10, color: '#fff', mb: 0,fontFamily: 'Basic',padding: 0, listStyle: 'none' }}>
//           <li>
//             <div sx={{ display: 'flex', justifyItems: 'center' }}>
//               <IconButton onClick={() => increaseQuantity(-1)}>
//                 <Minus width={18} height={18} />
//               </IconButton>

//               <label>
//                 <Input
//                   sx={{
//                     border: 'solid',
//                     borderColor: '#252525',
//                   borderWidth: '.50px', borderRadius: '10px',
//                     height: '100%',
//                     textAlign: 'center',
//                   }}
//                   type="number"
//                   max={99}
//                   min={0}
//                   value={quantity}
//                   onChange={handleQuantity}
//                   onBlur={handleBlur}
//                 />
//               </label>
//               <IconButton onClick={() => increaseQuantity(1)}>
//                 <Plus width={18} height={18} />
//               </IconButton>
//             </div>
//           </li>
//           {item.variant.selectedOptions.map((option: any) => (
//             <li key={option.value}>
//               {/* shows variant */}
//               {/* {option.name}:{option.value} */}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </Grid>
//   )
// }

// /**
//  *         

//  */

// export default CartItem
